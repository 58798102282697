<template>
  <master-layout bgColor="intro">

    <ion-slides pager="true" :options="slideOpts">
      <ion-slide>
        <img src="assets/images/intro-illustration-1.svg" class="Illustration"/>
        <h1>Borközösséget építünk</h1>
        <p class="mediumText">Fontos számunkra, hogy a lehető legtöbb emberhez jutassuk el a magyar borok szeretetét, a hazai pincék által képviselt értékeket.</p>
        <p class="bottomLink"><a @click="$router.push('/app/udvozoljuk')"><b>Bemutató átugrása</b></a></p>
      </ion-slide>
      <ion-slide>
        <img src="assets/images/intro-illustration-2.svg" class="Illustration"/>
        <h1>Borászatok felfedezése</h1>
        <p class="mediumText">Ehhez pedig egy olyan platformot hoztunk létre, ahol a legkisebb és a legnagyobb borászatok egyaránt képviselni tudják magukat.</p>
        <p class="bottomLink"><a @click="$router.push('/app/udvozoljuk')"><b>Bemutató átugrása</b></a></p>
      </ion-slide>
      <ion-slide>
        <img src="assets/images/intro-illustration-3.svg" class="Illustration"/>
        <h1>Bejelentkezés a<br/>borászatokból</h1>
        <p class="mediumText">Partner borászatainknál lehetőséged van "becsekkolni", bejelentkezni az applikációnkon keresztül, jelezve, hogy ott jártál. <b>A jövőben ezek a bejelentkezések sok-sok egyéb lehetőséget nyitnak majd meg előtted, így érdemes minél több helyen megtenned.</b></p>
        <ion-button shape="round" expand="block" color="primary" @click="$router.push('/app/udvozoljuk')">Kezdjük!</ion-button>
      </ion-slide>
    </ion-slides>

  </master-layout>
</template>

<script>
import {
  IonSlides, IonSlide, IonButton
} from '@ionic/vue'

export default {
  components: {
    IonSlides, IonSlide, IonButton
  },
  data () {
    return {
      slideOpts: {
        initialSlide: 0,
        speed: 400
      }
    }
  }
}
</script>

<style scoped>
ion-slides {
  height: 100%;
  background-color: #282433;
  padding-bottom: 100px !important;
  --bullet-background: #60588f;
  --bullet-background-active: #ffffff;
}
.swiper-slide{
  display: block;
  padding: 55px 20px 10px 20px;
}
/* .swiper-pagination, .swiper-pagination-bullets{
  margin-bottom: 50px;
  position:relative;
  top: -50px;
} */
h1, p{
  color: #ffffff;
  margin-bottom: 20px;
}
.bottomLink{
  font-size: 15px;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
</style>
